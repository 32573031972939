import Layout from "@/components/App/Layout";
import SEO from "@/components/SEO";
import BreadCrumb from "@/components/Shared/extra/BreadCrumb";
import React from "react";

const CareersPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        contentfulSeo={{
          metaDescription:
            "Get hired in one of the best mobile app development companies in toronto.",
          metaKeywords: [
            "job openings in top mobile app development company in toronto",
            " top mobile app development company in canada",
            " job vacancies at top android app development company in canada",
            " openings in mobile app development companies in toronto",
            " openings in game development companies in toronto",
            " careers in mobile app development companies in toronto",
            " job openings in flutter development companies in canada",
            " job openings for android developers in toronto",
            " job openings for ios developers in toronto",
            " job openings for flutter developers in toronto",
            " job openings for game developers in toronto",
          ],
          metaTitle: "Careers | Zartek Canada",
          metaUrl: "https://www.zartek.ca/careers/",
        }}
      />
      <BreadCrumb
        pageTitle="Careers"
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/careers/", pathName: "Careers" },
        ]}
      />
      <div className="pb-5 mt-5">
        <div className="pt-3">
          <iframe
            src="https://recruitcareers.zappyhire.com/en/zartek"
            title="zartek careers"
            frameBorder="0"
            height="100%"
            width="100%"
            style={{ minHeight: "100vh" }}
          ></iframe>
        </div>

        <div className="container mt-3">
          <strong>Note</strong> : Zartek or any employee will never ask any candidate favours,
          gifts, money. If you come across any suspicious behaviour, please send a mail to
          info@zartek.in. Please ignore job advertisements of a dubious nature. Do not share
          personal information, including OTPs sent to your mobile phone. Never transfer money to
          strangers or anyone you have never met.
        </div>
      </div>
    </Layout>
  );
};

export default CareersPage;
